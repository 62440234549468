(function (document) {
  const navButton = {
    body: document.querySelector("body"),
    navToggle: document.querySelector("#toggleNav"),
    nav: document.querySelector("nav"),

    doToggle: function (e) {
      e.preventDefault();
      this.navToggle.classList.toggle("open");
      this.nav.classList.toggle("active");
      this.body.classList.toggle("nav-open");
      this.navToggle.innerHTML =
        this.navToggle.innerHTML == "Menu" ? "Close" : "Menu";
    },
  };

  navButton.navToggle.addEventListener("click", function (e) {
    navButton.doToggle(e);
  });

  const sfbutton = document.querySelector("#switch-film-toggle");
  if (sfbutton) {
    sfbutton.addEventListener("click", function () {
      toggle("ibsf-onoff");
    });
  }
})(document);

function decryptEmail(encoded) {
  const address = atob(encoded);
  window.location.href = "mailto:" + address;
}

function drawIBPFpattern(id, c) {
  const tileSize = 12;
  const pointSize = 4;
  const canvas = id;
  const ctx = canvas.getContext("2d");
  canvas.width = c.clientWidth;
  canvas.height = c.clientHeight;
  ctx.fillStyle = "#000";
  for (let y = 0; y < canvas.height - tileSize; y += tileSize) {
    let s = (pointSize + map_range(y, 0, c.clientHeight, 0, 10)) / 2;
    for (let x = 0; x < canvas.width; x += tileSize) {
      ctx.beginPath();
      ctx.ellipse(x + tileSize / 2, y + tileSize / 2, s, s, 0, 0, 2 * Math.PI);
      ctx.fill();
    }
  }
}

function drawIBSFpattern(id, c) {
  const tileSize = 14;
  const lineSize = 12;
  const canvas = id;
  const ctx = canvas.getContext("2d");
  canvas.width = c.clientWidth;
  canvas.height = c.clientHeight;
  ctx.fillStyle = "#000";
  for (let y = 0; y < canvas.height - tileSize; y += tileSize) {
    for (let x = 0; x < canvas.width; x += tileSize) {
      ctx.beginPath();
      ctx.translate(x + tileSize / 2, y + tileSize / 2);
      let m = map_range(y, 0, canvas.height - tileSize * 2, Math.PI, 0);
      let r = map_range(getRandom(), 0, 1, 0, m);
      ctx.rotate(r);
      ctx.lineWidth = 2;
      ctx.moveTo(0, -lineSize / 2);
      ctx.lineTo(0, lineSize / 2);
      ctx.stroke();
      ctx.rotate(-r);
      ctx.translate(-x - tileSize / 2, -y - tileSize / 2);
    }
  }
}

function map_range(value, low1, high1, low2, high2) {
  return low2 + ((high2 - low2) * (value - low1)) / (high1 - low1);
}

function getRandom() {
  return Math.random();
}

function dE(encoded) {
  var address = atob(encoded);
  window.location.href = "mailto:" + address;
}

const onImg = "images/ibsf-off-c_1.jpg";
const offImg = "images/ibsf-on-c_1.jpg";

function toggle(id) {
  let img = document.getElementById(id);
  let imgsrc = img.getAttribute("src");
  let label = document.getElementById("switch");
  imgsrc = imgsrc == offImg ? onImg : offImg;
  label.classList.toggle("on");
  img.setAttribute("src", imgsrc);
}
